/**
 * Created J/03/12/2009
 * Updated M/11/03/2025
 *
 * Copyright 2008-2025 | Fabrice Creuzot (luigifab) <code~luigifab~fr>
 * https://www.luigifab.fr/
 *
 * This program is free software, you can redistribute it or modify
 * it under the terms of the GNU General Public License (GPL) as published
 * by the free software foundation, either version 2 of the license, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but without any warranty, without even the implied warranty of
 * merchantability or fitness for a particular purpose. See the
 * GNU General Public License (GPL) for more details.
 */

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& document &&& */
* { margin:0; padding:0; font-family:ubuntu, sans-serif; background-clip:padding-box; background-repeat:no-repeat; }
code, code *, pre, pre *, kbd { font-family:"Courier New", monospace; }

body {
	margin:1.6em auto 2.5em; width:60rem; overflow-y:scroll;
	font-size:0.85em; color:#222; background-color:#CCC;
	user-select:text; -ms-user-select:text; -moz-user-select:text; -webkit-user-select:text;
}

hr { display:none; }
abbr { border-bottom:0; text-decoration:none; }
button::-moz-focus-inner { margin:-1px; padding:0; border:0; outline:0; }
html, button, div.header, div.breadcrumbs ol, span.code { user-select:none; -ms-user-select:none; -moz-user-select:none; -webkit-user-select:none; }
body:not(.js) button, body:not(.js) select { visibility:hidden; }

img { overflow:hidden; vertical-align:middle; color:transparent; }
a img { display:inline-block; border:0; }
a { color:blue; text-decoration:none; }
a:hover, a:active, a:focus { color:purple; text-decoration:underline; }

@font-face {
	src:local("Schoolboy Text"), url("../fonts/doc-schoolboytext.ttf") format("truetype");
	font-family:SchoolboyText; font-style:normal; font-weight:400; font-display:swap;
}

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& texte &&& */
h1 {
	margin:1em 0;
	font-size:1.9em; font-weight:700;
}

h2 {
	display:flex; margin:2em 0 1.5em; padding:0 0.9em; line-height:2em;
	font-size:1.2em; font-weight:700; border-radius:0.6em; background-color:#E6E6EA;
}

h3 {
	margin:1.4em 1em 1.1em;
	font-size:1.1em; font-weight:700;
}

h4 {
	margin:1.4em 2.5em 1.1em; clear:both;
	font-size:0.95em; font-weight:700; font-style:italic;
}

h2 a, h3 a { flex:1; color:currentColor; }
h2 a:after, h3 a:after { content:"\0000A0⚓"; visibility:hidden; }
h2 a:hover, h2 a:active, h2 a:focus, h3 a:hover, h3 a:active, h3 a:focus { color:currentColor; text-decoration:none; }
h2 a:hover:after, h2 a:active:after, h2 a:focus:after, h3 a:hover:after, h3 a:active:after, h3 a:focus:after { visibility:visible; }

p { margin:0.8em 0 1em; line-height:1.42857143; hyphens:auto; -moz-hyphens:auto; -ms-hyphens:auto; -webkit-hyphens:auto; }
kbd  { display:inline-block; padding:0 0.2em; font-size:0.9em; }
code { display:inline-block; padding:0 0.3em; font-size:0.9em; background-color:#F5F5F5; }
code span.optional { font-style:italic; color:#676767; }
em { padding-right:1px; }

p.code strong { margin-left:0.3em; }
p.code button { margin-left:0.4em; }

/*rtl:begin:ignore*/
p.code code {
	display:block; margin:0.4em 0 0.8em; padding:0.5em 1em; overflow:hidden; text-align:left;
	white-space:pre-wrap; word-wrap:break-word; border-left:3px solid #BBB;
}

pre.highlight { position:relative; display:block; margin:1em 0; padding:0.6em 1em; text-align:left; border:1px solid currentColor; }
pre.highlight.zenburn, pre.highlight.fruity, pre.highlight.monokai, pre.highlight.native, pre.highlight.vim { border-color:transparent; }
pre.highlight span.code { font-size:1.2em; }

pre.highlight code {
	display:block; margin-top:0.7em; padding:0; line-height:1.42857143; overflow:hidden;
	white-space:pre-wrap; word-wrap:break-word; tab-size:4; -moz-tab-size:4; background-color:inherit;
}

pre.highlight code.dif { font-size:0.8em; }
pre.highlight code.dif ins, pre.highlight code.dif del { font-weight:700; text-decoration:none; }

pre.highlight select {
	position:absolute; top:0.6em; right:0.6em; padding:0; min-width:6em; text-align:right;
	font-size:0.75em; color:currentColor; border:0; background:transparent;
	appearance:none; -moz-appearance:none; -webkit-appearance:none;
}
/*rtl:end:ignore*/

label { cursor:pointer; }
select { cursor:pointer; font-size:1em; color:#222; border:1px solid #DDD; background-color:white; }
input[type="checkbox"], input[type="radio"] { cursor:pointer; vertical-align:middle; }
input:not([type="checkbox"]):not([type="radio"]) { margin:4px 0 !important; padding:3px 4px !important; border:1px solid #999; }
input:not([type="checkbox"]):not([type="radio"]):focus, select:focus { border-color:#666; }

select:not([multiple]) {
	padding:0 15px 0 0; min-width:40px; min-height:21px; line-height:19px; overflow:hidden; text-overflow:ellipsis;
	appearance:none; -moz-appearance:none; -webkit-appearance:none; text-indent:0; padding-inline-start:2px;
	background:white url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAAAAAD7BeiBAAAASklEQVR42mP4cenvfzD4cJPhdsNKMOdNTx/D/31gzuuejmcM//8faFjx91V357P/QPb/Qw2Lujqf/wez/x9p6HrxH8r+f+sdiAQAz1hCYXR8l24AAAAASUVORK5CYII=") calc(100% - 5px) center no-repeat;
}

button { cursor:pointer; font-size:0.95em; font-weight:400; color:blue; border:0; background:none; }
button:hover, button:active, button:focus { color:purple; text-decoration:underline; }
button:active, button:focus { outline:1px dotted; }

ol, ul { display:block; margin:0.8em 0 1.4em; }
ol ol { margin:0.45em 0 0.5em; }
li { display:block; margin:0.1em 0; line-height:1.42857143; }

ol.arrow, ul.arrow { margin:0.8em 0 0.9em; }
ol.arrow li:before, ul.arrow li:not(.code):before { content:"➤\0000A0"; }
p:not(.mc) + ul.arrow { margin-top:-0.5em; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& entête &&& */
div.header {
	padding:0 calc(1.2em + 1px); cursor:default;
	color:white; border-radius:1.2em 1.2em 0 0; background-color:#677082; background-image:linear-gradient(#33415D, #677082);
}

div.header [rel="home"] { display:inline-block; margin:0.4em 0; min-height:1.7em; font:2em SchoolboyText; font-weight:400; }
div.header [rel="home"] span { padding-left:1em; font-size:0.6em; }

div.header a { color:currentColor; text-decoration:none; transition:color 0.3s; }
div.header a:hover, div.header a:active, div.header a:focus, div.header strong { color:#EFB; }

div.header ul { display:flex; align-items:center; }
div.header ul li a { display:block; }
div.header ul li.current { color:#EFB; }
div.header ul li.current, div.header ul li a { padding:0.5em 0 0.6em; }

div.header ul.languages { float:right; margin:0; font-size:0.85em; }
div.header ul.languages li { margin:0 0 0 1.2em; }

div.header ul.menu { margin:0; font-weight:700; }
div.header ul.menu li { margin:0 1.2em 0 0; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& pied de page &&& */
div.footer {
	padding:1em 0; font-size:0.9em; text-align:center;
	color:#4A4A4A; border:1px solid #BBB; border-top:0; border-radius:0 0 1.2em 1.2em;
	background-color:#E2E5E6; background-image:linear-gradient(#E2E5E6, #EFEFEF);
}

div.footer p {
	margin:0;
}

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& fil d'ariane &&& */
div.breadcrumbs {
	display:flex; align-items:center; padding:0.5em 1.2em 0.45em; font-size:0.8em;
	color:#6F6F6F; border-left:1px solid #BBB; border-right:1px solid #BBB; border-bottom:1px solid #DDD; background-color:#F5F5F5;
}

div.breadcrumbs ol { display:flex; flex-grow:1; flex-wrap:wrap; margin:0; }
div.breadcrumbs li { margin:0; }
div.breadcrumbs li:not(:last-child):after { margin:0 0.3em; content:"/"; }

div.breadcrumbs a { color:currentColor; }
div.breadcrumbs a:hover, div.breadcrumbs a:active, div.breadcrumbs a:focus { color:black; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& page &&& */
div.main {
	padding:0.2em 1.2em 2.5em; overflow:hidden;
	border-left:1px solid #BBB; border-right:1px solid #BBB; background-color:white;
}

div.main h1 { min-height:1.6em; font:2.3em SchoolboyText; }
div.main h1 span, div.main h1 abbr { font-family:SchoolboyText; }

div.notice { margin:-0.2em -1.2em 0; }
div.notice p { margin:0; padding:1em 1.2em; font-size:0.85em; }
div.notice p.browser { color:white; background-color:red; }
div.notice p.browser a { color:yellow; }

p.review { display:flex; padding:0.5em; border:1px solid #CCC; }
p.review span { min-width:8rem; font-size:0.85em; font-style:italic; color:#767676; }
p.review span span { display:block; font-size:2.2em; font-style:normal; color:gold; letter-spacing:-0.1em; white-space:nowrap; }

img.screenright { display:block; float:right; margin:-1.5em 0 1em 1.4em; background-color:#EEE; }

hr.s { display:block; margin:1.3em 1em 1em; border:0; border-top:1px solid #CCC; }
dl.v { position:relative; padding:0.2em 0.2em 0.3em 4em; font-size:0.85em; line-height:1.42857143; border:1px solid #CCC; }
dl.v dt { position:absolute; left:0; padding:0.25em; width:3.5em; text-align:center; }
dl.v dd { padding:0.25em 0; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& slider &&& */
div.controls { margin:1.4em -1.4em 1.8em; padding:0.5em 0 0; text-align:center; background-color:#EFEFEF; }
div.controls label { margin:0 0.2em; }
div.controls input { vertical-align:text-bottom; }
div.controls input:checked + span { text-decoration:underline; }
div.controls div.screens { margin:0.5em 0 0; }
div.controls div.screens button { border-color:#EFEFEF; }

div.screens { position:relative; display:flex; align-items:center; margin:1.4em -1.4em 1.8em; padding:1em 2rem 0.8em; }

div.screens dl { flex:0 0 100%; text-align:center; transition:margin 0.3s; }
div.screens dt img { display:inline-block; background-color:#EEE; }
div.screens dd { padding-top:1.4em; }

div.screens button { position:absolute; top:0; bottom:0; width:50%; font-size:2em; color:#AAA; }
div.screens button:first-child { left:0; text-align:left; border-left:2rem solid white; }
div.screens button:last-of-type { right:0; text-align:right; border-right:2rem solid white; }
div.screens button:hover, div.screens button:active, div.screens button:focus { color:black; outline:none; }
div.screens button span { display:inline-block; margin:0 -2rem; width:2rem; text-align:center; }

/* &&&&&&&&&&&&&&&&&&&& sommaire et options &&& */
div.contents {
	float:right; margin:-7.5em -2px 1em 1em; padding:1em; width:24em;
	font-size:0.85em; border:1px solid #BBB; border-radius:0.8em; background-color:#FFEFEF;
}

div.contents h2 { display:block; margin:0 0 0.7em; padding:0; line-height:inherit; background:none; }
div.contents h2 span { float:right; font-size:0.85em; font-weight:400; }

div.contents a.logo {
	display:block; margin:auto; width:85%; height:100px;
	color:transparent; background:no-repeat center; background-size: contain; opacity:0.2;
}

div.contents a.logo.gtk { margin-top:1em; background-image:url("../gtk/images/logo-gtk.svg"); }
div.contents a.logo.python { background-image:url("../python/images/logo-python.svg"); }
div.contents a.logo.adminer { background-image:url("../adminer/images/logo-adminer.png"); }
div.contents a.logo.redmine { background-image:url("../redmine/images/logo-redmine.png"); }
div.contents a.logo.openmage { background-image:url("../openmage/images/logo-openmage.svg"); }
div.contents a.logo.dolibarr { background-image:url("../dolibarr/images/logo-dolibarr.png"); }
div.contents a.logo.maho { background-image:url("../maho/images/logo-maho.svg"); }

div.contents ol { margin:0; counter-reset:contents; }
div.contents li { line-height:135%; }
div.contents ol li:before { content:counter(contents) ". "; counter-increment:contents; }

div.contents ol ol { counter-reset:contents2; }
div.contents ol ol li:before { content:counter(contents) "." counter(contents2) ". "; counter-increment:contents2; }

div.contents form div { margin-top:1em; padding-top:1em; border-top:1px solid #DDD; }
div.contents form div:last-child { display:flex; flex-wrap:wrap; justify-content:space-between; }
div.contents form button { margin-bottom:0.4em; color:black; }
div.contents form code { background-color:inherit; }

div.contents form ul { margin:0; }
div.contents form li li { line-height:195%; }
div.contents form li select { width:9em; vertical-align:middle; } /* vertical-align pour Chrome 31 et Opera 19 */
div.contents form li option[selected] { font-weight:700; }
div.contents form li select[disabled], div.contents form li select[disabled] + code { opacity:0.4; }

/* &&&&&&&&&&&&&&&&&&&& tableau des tailles &&& */
table.sizes { float:right; margin-left:2em; font-size:0.85em; border-collapse:collapse; }

table.sizes thead th { padding:0.3em 0.6em; min-width:5.6em; text-align:center; border-bottom:1px dotted #DDD; border-right:1px dotted #DDD; }
table.sizes thead th:first-child { text-align:left; }

table.sizes tbody td:first-child { text-align:left; white-space:normal; }
table.sizes tbody td { padding:0.3em 0.6em; text-align:right; border-right:1px dotted #DDD; }

table.sizes tbody tr:hover { background-color:#EFEFEF; }

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& media queries &&& */
@media screen and (max-width:61rem),(max-device-width:61rem) { /* 976px */

	body { margin:0; width:auto; }
	div.header, div.main, div.footer { border:0; border-radius:0; }
	div.breadcrumbs { border-left:0; border-right:0; }

	div.main h1 { margin:0.5em 0; }

	div.contents { float:inherit; display:inline-flex; margin:0; width:auto; font-size:0.95em; background-color:inherit; }
	div.contents a.logo { display:none; }
	div.contents form div { margin:0 0 1em 1.5em; padding-top:0; border:0; }
	div.contents form div:last-child { flex-direction:column; align-items:flex-start; }
}

@media screen and (max-width:51rem),(max-device-width:51rem) { /* 816px */
	br.np { display:none; }
	div.breadcrumbs { flex-direction:column; align-items:flex-start; }
}

@media screen and (max-width:41rem),(max-device-width:41rem) { /* 656px */

	div.header { padding:0 calc(0.8em + 1px); }
	div.footer { padding:1em 0; }
	div.breadcrumbs { padding:0.5em 0.8em 0.45em; }
	div.main { padding:0.2em 0.8em 2.5em; }
	div.notice { margin:-0.2em -0.8em 0; }
	div.notice p { padding:1em 0.8em; }

	div.contents { flex-direction:column; }
	div.contents form div { margin:0.5em 0 -0.2em; }

	p.review { flex-direction:column; }
	p.review > span { min-height:5rem; }
}

@media screen {

	body.debug * { text-decoration:none !important; }
	body.debug a { text-decoration:underline !important; }

	body.debug [type] { font-weight:400; border:1px solid black; color:black !important; background-color:aqua !important; }
	body.debug [type]:before { font-weight:400; text-decoration:none; color:black; opacity:1; content:"[" attr(type) "] " !important; }

	body.debug [rel] { font-weight:400; border:1px solid black; color:black !important; background-color:pink !important; }
	body.debug [rel]:before { font-weight:400; text-decoration:none; color:black; opacity:1; content:"[" attr(rel) "] " !important; }

	body.debug [title] { font-weight:400; border:1px solid black; color:black !important; background-color:orange !important; }
	body.debug [title]:before { font-weight:400; text-decoration:none; color:black; opacity:1; content:"[" attr(title) "] " !important; }

	body.debug [lang] { font-weight:400; border:1px solid black; color:black !important; background-color:yellow !important; }
	body.debug [lang]:before { font-weight:400; text-decoration:none; color:black; opacity:1; content:"[" attr(lang) "] " !important; }

	body.debug [hreflang] { font-weight:400; border:1px solid black; color:white !important; background-color:blue !important; }
	body.debug [hreflang]:before { font-weight:400; text-decoration:none; color:white; opacity:1; content:"[" attr(hreflang) "] " !important; }

	body.debug a[lang][hreflang]:before { content:"[" attr(lang) "/" attr(hreflang) "] " !important; }
	body.debug code * { color:black !important; }
}

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& pygments &&& **
 * https://github.com/richleland/pygments-css
 * https://github.com/jwarby/jekyll-pygments-themes (zenburn/github) */
@media screen {
	.zenburn { color:#fdce93; background-color:#3f3f3f; }
	.zenburn .hll { background-color:#222; }
	.zenburn .c { color:#7f9f7f; }
	.zenburn .err { color:#e37170; background-color:#3d3535; }
	.zenburn .g { color:#7f9f7f; }
	.zenburn .k { color:#f0dfaf; }
	.zenburn .l { color:#ccc; }
	.zenburn .n { color:#dcdccc; }
	.zenburn .o { color:#f0efd0; }
	.zenburn .x { color:#ccc; }
	.zenburn .p { color:#dbdbcb; }
	.zenburn .cm { color:#7f9f7f; }
	.zenburn .cp { color:#7f9f7f; }
	.zenburn .c1 { color:#7f9f7f; }
	.zenburn .cs { color:#cd0000; font-weight:700; }
	.zenburn .gd { color:#cd0000; }
	.zenburn .ge { color:#ccc; font-style:italic; }
	.zenburn .gr { color:red; }
	.zenburn .gh { color:#dcdccc; font-weight:700; }
	.zenburn .gi { color:#00cd00; }
	.zenburn .go { color:gray; }
	.zenburn .gp { color:#dcdccc; font-weight:700; }
	.zenburn .gs { color:#ccc; font-weight:700; }
	.zenburn .gu { color:purple; font-weight:700; }
	.zenburn .gt { color:#0040D0; }
	.zenburn .kc { color:#dca3a3; }
	.zenburn .kd { color:#ffff86; }
	.zenburn .kn { color:#dfaf8f; font-weight:700; }
	.zenburn .kp { color:#cdcf99; }
	.zenburn .kr { color:#cdcd00; }
	.zenburn .kt { color:#00cd00; }
	.zenburn .ld { color:#cc9393; }
	.zenburn .m { color:#8cd0d3; }
	.zenburn .s { color:#cc9393; }
	.zenburn .na { color:#9ac39f; }
	.zenburn .nb { color:#efef8f; }
	.zenburn .nc { color:#efef8f; }
	.zenburn .no { color:#ccc; }
	.zenburn .nd { color:#ccc; }
	.zenburn .ni { color:#c28182; }
	.zenburn .ne { color:#c3bf9f; font-weight:700; }
	.zenburn .nf { color:#efef8f; }
	.zenburn .nl { color:#ccc; }
	.zenburn .nn { color:#8fbede; }
	.zenburn .nx { color:#ccc; }
	.zenburn .py { color:#ccc; }
	.zenburn .nt { color:#9ac39f; }
	.zenburn .nv { color:#dcdccc; }
	.zenburn .ow { color:#f0efd0; }
	.zenburn .w { color:#ccc; }
	.zenburn .mf { color:#8cd0d3; }
	.zenburn .mh { color:#8cd0d3; }
	.zenburn .mi { color:#8cd0d3; }
	.zenburn .mo { color:#8cd0d3; }
	.zenburn .sb { color:#cc9393; }
	.zenburn .sc { color:#cc9393; }
	.zenburn .sd { color:#cc9393; }
	.zenburn .s2 { color:#cc9393; }
	.zenburn .se { color:#cc9393; }
	.zenburn .sh { color:#cc9393; }
	.zenburn .si { color:#cc9393; }
	.zenburn .sx { color:#cc9393; }
	.zenburn .sr { color:#cc9393; }
	.zenburn .s1 { color:#cc9393; }
	.zenburn .ss { color:#cc9393; }
	.zenburn .bp { color:#efef8f; }
	.zenburn .vc { color:#efef8f; }
	.zenburn .vg { color:#dcdccc; }
	.zenburn .vi { color:#ffffc7; }
	.zenburn .il { color:#8cd0d3; }

	.github { background:#ffffff; }
	.github .hll { background-color:#ffffcc; }
	.github .c { color:#999988; font-style:italic; }
	.github .err { color:#a61717; background-color:#e3d2d2; }
	.github .k { color:#000000; font-weight:700; }
	.github .o { color:#000000; font-weight:700; }
	.github .cm { color:#999988; font-style:italic; }
	.github .cp { color:#999999; font-weight:700; font-style:italic; }
	.github .c1 { color:#999988; font-style:italic; }
	.github .cs { color:#999999; font-weight:700; font-style:italic; }
	.github .gd { color:#000000; background-color:#ffdddd; }
	.github .ge { color:#000000; font-style:italic; }
	.github .gr { color:#aa0000; }
	.github .gh { color:#999999; }
	.github .gi { color:#000000; background-color:#ddffdd; }
	.github .go { color:#888888; }
	.github .gp { color:#555555; }
	.github .gs { font-weight:700; }
	.github .gu { color:#aaaaaa; }
	.github .gt { color:#aa0000; }
	.github .kc { color:#000000; font-weight:700; }
	.github .kd { color:#000000; font-weight:700; }
	.github .kn { color:#000000; font-weight:700; }
	.github .kp { color:#000000; font-weight:700; }
	.github .kr { color:#000000; font-weight:700; }
	.github .kt { color:#445588; font-weight:700; }
	.github .m { color:#009999; }
	.github .s { color:#d01040; }
	.github .na { color:#008080; }
	.github .nb { color:#0086B3; }
	.github .nc { color:#445588; font-weight:700; }
	.github .no { color:#008080; }
	.github .nd { color:#3c5d5d; font-weight:700; }
	.github .ni { color:#800080; }
	.github .ne { color:#990000; font-weight:700; }
	.github .nf { color:#990000; font-weight:700; }
	.github .nl { color:#990000; font-weight:700; }
	.github .nn { color:#555555; }
	.github .nt { color:#000080; }
	.github .nv { color:#008080; }
	.github .ow { color:#000000; font-weight:700; }
	.github .w { color:#bbbbbb; }
	.github .mf { color:#009999; }
	.github .mh { color:#009999; }
	.github .mi { color:#009999; }
	.github .mo { color:#009999; }
	.github .sb { color:#d01040; }
	.github .sc { color:#d01040; }
	.github .sd { color:#d01040; }
	.github .s2 { color:#d01040; }
	.github .se { color:#d01040; }
	.github .sh { color:#d01040; }
	.github .si { color:#d01040; }
	.github .sx { color:#d01040; }
	.github .sr { color:#009926; }
	.github .s1 { color:#d01040; }
	.github .ss { color:#990073; }
	.github .bp { color:#999999; }
	.github .vc { color:#008080; }
	.github .vg { color:#008080; }
	.github .vi { color:#008080; }
	.github .il { color:#009999; }

	.default { background:#f8f8f8; }
	.default .hll { background-color:#ffffcc; }
	.default .c { color:#408080; font-style:italic; }
	.default .err { border:1px solid #FF0000; }
	.default .k { color:#008000; font-weight:700; }
	.default .o { color:#666666; }
	.default .ch { color:#408080; font-style:italic; }
	.default .cm { color:#408080; font-style:italic; }
	.default .cp { color:#BC7A00; }
	.default .cpf { color:#408080; font-style:italic; }
	.default .c1 { color:#408080; font-style:italic; }
	.default .cs { color:#408080; font-style:italic; }
	.default .gd { color:#A00000; }
	.default .ge { font-style:italic; }
	.default .gr { color:#FF0000; }
	.default .gh { color:#000080; font-weight:700; }
	.default .gi { color:#00A000; }
	.default .go { color:#888888; }
	.default .gp { color:#000080; font-weight:700; }
	.default .gs { font-weight:700; }
	.default .gu { color:#800080; font-weight:700; }
	.default .gt { color:#0044DD; }
	.default .kc { color:#008000; font-weight:700; }
	.default .kd { color:#008000; font-weight:700; }
	.default .kn { color:#008000; font-weight:700; }
	.default .kp { color:#008000; }
	.default .kr { color:#008000; font-weight:700; }
	.default .kt { color:#B00040; }
	.default .m { color:#666666; }
	.default .s { color:#BA2121; }
	.default .na { color:#7D9029; }
	.default .nb { color:#008000; }
	.default .nc { color:#0000FF; font-weight:700; }
	.default .no { color:#880000; }
	.default .nd { color:#AA22FF; }
	.default .ni { color:#999999; font-weight:700; }
	.default .ne { color:#D2413A; font-weight:700; }
	.default .nf { color:#0000FF; }
	.default .nl { color:#A0A000; }
	.default .nn { color:#0000FF; font-weight:700; }
	.default .nt { color:#008000; font-weight:700; }
	.default .nv { color:#19177C; }
	.default .ow { color:#AA22FF; font-weight:700; }
	.default .w { color:#bbbbbb; }
	.default .mb { color:#666666; }
	.default .mf { color:#666666; }
	.default .mh { color:#666666; }
	.default .mi { color:#666666; }
	.default .mo { color:#666666; }
	.default .sa { color:#BA2121; }
	.default .sb { color:#BA2121; }
	.default .sc { color:#BA2121; }
	.default .dl { color:#BA2121; }
	.default .sd { color:#BA2121; font-style:italic; }
	.default .s2 { color:#BA2121; }
	.default .se { color:#BB6622; font-weight:700; }
	.default .sh { color:#BA2121; }
	.default .si { color:#BB6688; font-weight:700; }
	.default .sx { color:#008000; }
	.default .sr { color:#BB6688; }
	.default .s1 { color:#BA2121; }
	.default .ss { color:#19177C; }
	.default .bp { color:#008000; }
	.default .fm { color:#0000FF; }
	.default .vc { color:#19177C; }
	.default .vg { color:#19177C; }
	.default .vi { color:#19177C; }
	.default .vm { color:#19177C; }
	.default .il { color:#666666; }

	.autumn { background:#ffffff; }
	.autumn .hll { background-color:#ffffcc; }
	.autumn .c { color:#aaaaaa; font-style:italic; }
	.autumn .err { color:#FF0000; background-color:#FFAAAA; }
	.autumn .k { color:#0000aa; }
	.autumn .ch { color:#aaaaaa; font-style:italic; }
	.autumn .cm { color:#aaaaaa; font-style:italic; }
	.autumn .cp { color:#4c8317; }
	.autumn .cpf { color:#aaaaaa; font-style:italic; }
	.autumn .c1 { color:#aaaaaa; font-style:italic; }
	.autumn .cs { color:#0000aa; font-style:italic; }
	.autumn .gd { color:#aa0000; }
	.autumn .ge { font-style:italic; }
	.autumn .gr { color:#aa0000; }
	.autumn .gh { color:#000080; font-weight:700; }
	.autumn .gi { color:#00aa00; }
	.autumn .go { color:#888888; }
	.autumn .gp { color:#555555; }
	.autumn .gs { font-weight:700; }
	.autumn .gu { color:#800080; font-weight:700; }
	.autumn .gt { color:#aa0000; }
	.autumn .kc { color:#0000aa; }
	.autumn .kd { color:#0000aa; }
	.autumn .kn { color:#0000aa; }
	.autumn .kp { color:#0000aa; }
	.autumn .kr { color:#0000aa; }
	.autumn .kt { color:#00aaaa; }
	.autumn .m { color:#009999; }
	.autumn .s { color:#aa5500; }
	.autumn .na { color:#1e90ff; }
	.autumn .nb { color:#00aaaa; }
	.autumn .nc { color:#00aa00; text-decoration:underline; }
	.autumn .no { color:#aa0000; }
	.autumn .nd { color:#888888; }
	.autumn .ni { color:#880000; font-weight:700; }
	.autumn .nf { color:#00aa00; }
	.autumn .nn { color:#00aaaa; text-decoration:underline; }
	.autumn .nt { color:#1e90ff; font-weight:700; }
	.autumn .nv { color:#aa0000; }
	.autumn .ow { color:#0000aa; }
	.autumn .w { color:#bbbbbb; }
	.autumn .mb { color:#009999; }
	.autumn .mf { color:#009999; }
	.autumn .mh { color:#009999; }
	.autumn .mi { color:#009999; }
	.autumn .mo { color:#009999; }
	.autumn .sa { color:#aa5500; }
	.autumn .sb { color:#aa5500; }
	.autumn .sc { color:#aa5500; }
	.autumn .dl { color:#aa5500; }
	.autumn .sd { color:#aa5500; }
	.autumn .s2 { color:#aa5500; }
	.autumn .se { color:#aa5500; }
	.autumn .sh { color:#aa5500; }
	.autumn .si { color:#aa5500; }
	.autumn .sx { color:#aa5500; }
	.autumn .sr { color:#009999; }
	.autumn .s1 { color:#aa5500; }
	.autumn .ss { color:#0000aa; }
	.autumn .bp { color:#00aaaa; }
	.autumn .fm { color:#00aa00; }
	.autumn .vc { color:#aa0000; }
	.autumn .vg { color:#aa0000; }
	.autumn .vi { color:#aa0000; }
	.autumn .vm { color:#aa0000; }
	.autumn .il { color:#009999; }

	.borland { background:#ffffff; }
	.borland .hll { background-color:#ffffcc; }
	.borland .c { color:#008800; font-style:italic; }
	.borland .err { color:#a61717; background-color:#e3d2d2; }
	.borland .k { color:#000080; font-weight:700; }
	.borland .ch { color:#008800; font-style:italic; }
	.borland .cm { color:#008800; font-style:italic; }
	.borland .cp { color:#008080; }
	.borland .cpf { color:#008800; font-style:italic; }
	.borland .c1 { color:#008800; font-style:italic; }
	.borland .cs { color:#008800; font-weight:700; }
	.borland .gd { color:#000000; background-color:#ffdddd; }
	.borland .ge { font-style:italic; }
	.borland .gr { color:#aa0000; }
	.borland .gh { color:#999999; }
	.borland .gi { color:#000000; background-color:#ddffdd; }
	.borland .go { color:#888888; }
	.borland .gp { color:#555555; }
	.borland .gs { font-weight:700; }
	.borland .gu { color:#aaaaaa; }
	.borland .gt { color:#aa0000; }
	.borland .kc { color:#000080; font-weight:700; }
	.borland .kd { color:#000080; font-weight:700; }
	.borland .kn { color:#000080; font-weight:700; }
	.borland .kp { color:#000080; font-weight:700; }
	.borland .kr { color:#000080; font-weight:700; }
	.borland .kt { color:#000080; font-weight:700; }
	.borland .m { color:#0000FF; }
	.borland .s { color:#0000FF; }
	.borland .na { color:#FF0000; }
	.borland .nt { color:#000080; font-weight:700; }
	.borland .ow { font-weight:700; }
	.borland .w { color:#bbbbbb; }
	.borland .mb { color:#0000FF; }
	.borland .mf { color:#0000FF; }
	.borland .mh { color:#0000FF; }
	.borland .mi { color:#0000FF; }
	.borland .mo { color:#0000FF; }
	.borland .sa { color:#0000FF; }
	.borland .sb { color:#0000FF; }
	.borland .sc { color:#800080; }
	.borland .dl { color:#0000FF; }
	.borland .sd { color:#0000FF; }
	.borland .s2 { color:#0000FF; }
	.borland .se { color:#0000FF; }
	.borland .sh { color:#0000FF; }
	.borland .si { color:#0000FF; }
	.borland .sx { color:#0000FF; }
	.borland .sr { color:#0000FF; }
	.borland .s1 { color:#0000FF; }
	.borland .ss { color:#0000FF; }
	.borland .il { color:#0000FF; }

	.bw { background:#ffffff; }
	.bw .hll { background-color:#ffffcc; }
	.bw .c { font-style:italic; }
	.bw .err { border:1px solid #FF0000; }
	.bw .k { font-weight:700; }
	.bw .ch { font-style:italic; }
	.bw .cm { font-style:italic; }
	.bw .cpf { font-style:italic; }
	.bw .c1 { font-style:italic; }
	.bw .cs { font-style:italic; }
	.bw .ge { font-style:italic; }
	.bw .gh { font-weight:700; }
	.bw .gp { font-weight:700; }
	.bw .gs { font-weight:700; }
	.bw .gu { font-weight:700; }
	.bw .kc { font-weight:700; }
	.bw .kd { font-weight:700; }
	.bw .kn { font-weight:700; }
	.bw .kr { font-weight:700; }
	.bw .s { font-style:italic; }
	.bw .nc { font-weight:700; }
	.bw .ni { font-weight:700; }
	.bw .ne { font-weight:700; }
	.bw .nn { font-weight:700; }
	.bw .nt { font-weight:700; }
	.bw .ow { font-weight:700; }
	.bw .sa { font-style:italic; }
	.bw .sb { font-style:italic; }
	.bw .sc { font-style:italic; }
	.bw .dl { font-style:italic; }
	.bw .sd { font-style:italic; }
	.bw .s2 { font-style:italic; }
	.bw .se { font-weight:700; font-style:italic; }
	.bw .sh { font-style:italic; }
	.bw .si { font-weight:700; font-style:italic; }
	.bw .sx { font-style:italic; }
	.bw .sr { font-style:italic; }
	.bw .s1 { font-style:italic; }
	.bw .ss { font-style:italic; }

	.colorful { background:#ffffff; }
	.colorful .hll { background-color:#ffffcc; }
	.colorful .c { color:#888888; }
	.colorful .err { color:#FF0000; background-color:#FFAAAA; }
	.colorful .k { color:#008800; font-weight:700; }
	.colorful .o { color:#333333; }
	.colorful .ch { color:#888888; }
	.colorful .cm { color:#888888; }
	.colorful .cp { color:#557799; }
	.colorful .cpf { color:#888888; }
	.colorful .c1 { color:#888888; }
	.colorful .cs { color:#cc0000; font-weight:700; }
	.colorful .gd { color:#A00000; }
	.colorful .ge { font-style:italic; }
	.colorful .gr { color:#FF0000; }
	.colorful .gh { color:#000080; font-weight:700; }
	.colorful .gi { color:#00A000; }
	.colorful .go { color:#888888; }
	.colorful .gp { color:#c65d09; font-weight:700; }
	.colorful .gs { font-weight:700; }
	.colorful .gu { color:#800080; font-weight:700; }
	.colorful .gt { color:#0044DD; }
	.colorful .kc { color:#008800; font-weight:700; }
	.colorful .kd { color:#008800; font-weight:700; }
	.colorful .kn { color:#008800; font-weight:700; }
	.colorful .kp { color:#003388; font-weight:700; }
	.colorful .kr { color:#008800; font-weight:700; }
	.colorful .kt { color:#333399; font-weight:700; }
	.colorful .m { color:#6600EE; font-weight:700; }
	.colorful .s { background-color:#fff0f0; }
	.colorful .na { color:#0000CC; }
	.colorful .nb { color:#007020; }
	.colorful .nc { color:#BB0066; font-weight:700; }
	.colorful .no { color:#003366; font-weight:700; }
	.colorful .nd { color:#555555; font-weight:700; }
	.colorful .ni { color:#880000; font-weight:700; }
	.colorful .ne { color:#FF0000; font-weight:700; }
	.colorful .nf { color:#0066BB; font-weight:700; }
	.colorful .nl { color:#997700; font-weight:700; }
	.colorful .nn { color:#0e84b5; font-weight:700; }
	.colorful .nt { color:#007700; }
	.colorful .nv { color:#996633; }
	.colorful .ow { color:#000000; font-weight:700; }
	.colorful .w { color:#bbbbbb; }
	.colorful .mb { color:#6600EE; font-weight:700; }
	.colorful .mf { color:#6600EE; font-weight:700; }
	.colorful .mh { color:#005588; font-weight:700; }
	.colorful .mi { color:#0000DD; font-weight:700; }
	.colorful .mo { color:#4400EE; font-weight:700; }
	.colorful .sa { background-color:#fff0f0; }
	.colorful .sb { background-color:#fff0f0; }
	.colorful .sc { color:#0044DD; }
	.colorful .dl { background-color:#fff0f0; }
	.colorful .sd { color:#DD4422; }
	.colorful .s2 { background-color:#fff0f0; }
	.colorful .se { color:#666666; font-weight:700; background-color:#fff0f0; }
	.colorful .sh { background-color:#fff0f0; }
	.colorful .si { background-color:#eeeeee; }
	.colorful .sx { color:#DD2200; background-color:#fff0f0; }
	.colorful .sr { color:#000000; background-color:#fff0ff; }
	.colorful .s1 { background-color:#fff0f0; }
	.colorful .ss { color:#AA6600; }
	.colorful .bp { color:#007020; }
	.colorful .fm { color:#0066BB; font-weight:700; }
	.colorful .vc { color:#336699; }
	.colorful .vg { color:#dd7700; font-weight:700; }
	.colorful .vi { color:#3333BB; }
	.colorful .vm { color:#996633; }
	.colorful .il { color:#0000DD; font-weight:700; }

	.emacs { background:#f8f8f8; }
	.emacs .hll { background-color:#ffffcc; }
	.emacs .c { color:#008800; font-style:italic; }
	.emacs .err { border:1px solid #FF0000; }
	.emacs .k { color:#AA22FF; font-weight:700; }
	.emacs .o { color:#666666; }
	.emacs .ch { color:#008800; font-style:italic; }
	.emacs .cm { color:#008800; font-style:italic; }
	.emacs .cp { color:#008800; }
	.emacs .cpf { color:#008800; font-style:italic; }
	.emacs .c1 { color:#008800; font-style:italic; }
	.emacs .cs { color:#008800; font-weight:700; }
	.emacs .gd { color:#A00000; }
	.emacs .ge { font-style:italic; }
	.emacs .gr { color:#FF0000; }
	.emacs .gh { color:#000080; font-weight:700; }
	.emacs .gi { color:#00A000; }
	.emacs .go { color:#888888; }
	.emacs .gp { color:#000080; font-weight:700; }
	.emacs .gs { font-weight:700; }
	.emacs .gu { color:#800080; font-weight:700; }
	.emacs .gt { color:#0044DD; }
	.emacs .kc { color:#AA22FF; font-weight:700; }
	.emacs .kd { color:#AA22FF; font-weight:700; }
	.emacs .kn { color:#AA22FF; font-weight:700; }
	.emacs .kp { color:#AA22FF; }
	.emacs .kr { color:#AA22FF; font-weight:700; }
	.emacs .kt { color:#00BB00; font-weight:700; }
	.emacs .m { color:#666666; }
	.emacs .s { color:#BB4444; }
	.emacs .na { color:#BB4444; }
	.emacs .nb { color:#AA22FF; }
	.emacs .nc { color:#0000FF; }
	.emacs .no { color:#880000; }
	.emacs .nd { color:#AA22FF; }
	.emacs .ni { color:#999999; font-weight:700; }
	.emacs .ne { color:#D2413A; font-weight:700; }
	.emacs .nf { color:#00A000; }
	.emacs .nl { color:#A0A000; }
	.emacs .nn { color:#0000FF; font-weight:700; }
	.emacs .nt { color:#008000; font-weight:700; }
	.emacs .nv { color:#B8860B; }
	.emacs .ow { color:#AA22FF; font-weight:700; }
	.emacs .w { color:#bbbbbb; }
	.emacs .mb { color:#666666; }
	.emacs .mf { color:#666666; }
	.emacs .mh { color:#666666; }
	.emacs .mi { color:#666666; }
	.emacs .mo { color:#666666; }
	.emacs .sa { color:#BB4444; }
	.emacs .sb { color:#BB4444; }
	.emacs .sc { color:#BB4444; }
	.emacs .dl { color:#BB4444; }
	.emacs .sd { color:#BB4444; font-style:italic; }
	.emacs .s2 { color:#BB4444; }
	.emacs .se { color:#BB6622; font-weight:700; }
	.emacs .sh { color:#BB4444; }
	.emacs .si { color:#BB6688; font-weight:700; }
	.emacs .sx { color:#008000; }
	.emacs .sr { color:#BB6688; }
	.emacs .s1 { color:#BB4444; }
	.emacs .ss { color:#B8860B; }
	.emacs .bp { color:#AA22FF; }
	.emacs .fm { color:#00A000; }
	.emacs .vc { color:#B8860B; }
	.emacs .vg { color:#B8860B; }
	.emacs .vi { color:#B8860B; }
	.emacs .vm { color:#B8860B; }
	.emacs .il { color:#666666; }

	.friendly { background:#f0f0f0; }
	.friendly .hll { background-color:#ffffcc; }
	.friendly .c { color:#60a0b0; font-style:italic; }
	.friendly .err { border:1px solid #FF0000; }
	.friendly .k { color:#007020; font-weight:700; }
	.friendly .o { color:#666666; }
	.friendly .ch { color:#60a0b0; font-style:italic; }
	.friendly .cm { color:#60a0b0; font-style:italic; }
	.friendly .cp { color:#007020; }
	.friendly .cpf { color:#60a0b0; font-style:italic; }
	.friendly .c1 { color:#60a0b0; font-style:italic; }
	.friendly .cs { color:#60a0b0; background-color:#fff0f0; }
	.friendly .gd { color:#A00000; }
	.friendly .ge { font-style:italic; }
	.friendly .gr { color:#FF0000; }
	.friendly .gh { color:#000080; font-weight:700; }
	.friendly .gi { color:#00A000; }
	.friendly .go { color:#888888; }
	.friendly .gp { color:#c65d09; font-weight:700; }
	.friendly .gs { font-weight:700; }
	.friendly .gu { color:#800080; font-weight:700; }
	.friendly .gt { color:#0044DD; }
	.friendly .kc { color:#007020; font-weight:700; }
	.friendly .kd { color:#007020; font-weight:700; }
	.friendly .kn { color:#007020; font-weight:700; }
	.friendly .kp { color:#007020; }
	.friendly .kr { color:#007020; font-weight:700; }
	.friendly .kt { color:#902000; }
	.friendly .m { color:#40a070; }
	.friendly .s { color:#4070a0; }
	.friendly .na { color:#4070a0; }
	.friendly .nb { color:#007020; }
	.friendly .nc { color:#0e84b5; font-weight:700; }
	.friendly .no { color:#60add5; }
	.friendly .nd { color:#555555; font-weight:700; }
	.friendly .ni { color:#d55537; font-weight:700; }
	.friendly .ne { color:#007020; }
	.friendly .nf { color:#06287e; }
	.friendly .nl { color:#002070; font-weight:700; }
	.friendly .nn { color:#0e84b5; font-weight:700; }
	.friendly .nt { color:#062873; font-weight:700; }
	.friendly .nv { color:#bb60d5; }
	.friendly .ow { color:#007020; font-weight:700; }
	.friendly .w { color:#bbbbbb; }
	.friendly .mb { color:#40a070; }
	.friendly .mf { color:#40a070; }
	.friendly .mh { color:#40a070; }
	.friendly .mi { color:#40a070; }
	.friendly .mo { color:#40a070; }
	.friendly .sa { color:#4070a0; }
	.friendly .sb { color:#4070a0; }
	.friendly .sc { color:#4070a0; }
	.friendly .dl { color:#4070a0; }
	.friendly .sd { color:#4070a0; font-style:italic; }
	.friendly .s2 { color:#4070a0; }
	.friendly .se { color:#4070a0; font-weight:700; }
	.friendly .sh { color:#4070a0; }
	.friendly .si { color:#70a0d0; font-style:italic; }
	.friendly .sx { color:#c65d09; }
	.friendly .sr { color:#235388; }
	.friendly .s1 { color:#4070a0; }
	.friendly .ss { color:#517918; }
	.friendly .bp { color:#007020; }
	.friendly .fm { color:#06287e; }
	.friendly .vc { color:#bb60d5; }
	.friendly .vg { color:#bb60d5; }
	.friendly .vi { color:#bb60d5; }
	.friendly .vm { color:#bb60d5; }
	.friendly .il { color:#40a070; }

	.fruity { background:#111111; color:#ffffff; }
	.fruity .hll { background-color:#333333; }
	.fruity .c { color:#008800; font-style:italic; background-color:#0f140f; }
	.fruity .err { color:#ffffff; }
	.fruity .esc { color:#ffffff; }
	.fruity .g { color:#ffffff; }
	.fruity .k { color:#fb660a; font-weight:700; }
	.fruity .l { color:#ffffff; }
	.fruity .n { color:#ffffff; }
	.fruity .o { color:#ffffff; }
	.fruity .x { color:#ffffff; }
	.fruity .p { color:#ffffff; }
	.fruity .ch { color:#008800; font-style:italic; background-color:#0f140f; }
	.fruity .cm { color:#008800; font-style:italic; background-color:#0f140f; }
	.fruity .cp { color:#ff0007; font-weight:700; font-style:italic; background-color:#0f140f; }
	.fruity .cpf { color:#008800; font-style:italic; background-color:#0f140f; }
	.fruity .c1 { color:#008800; font-style:italic; background-color:#0f140f; }
	.fruity .cs { color:#008800; font-style:italic; background-color:#0f140f; }
	.fruity .gd { color:#ffffff; }
	.fruity .ge { color:#ffffff; }
	.fruity .gr { color:#ffffff; }
	.fruity .gh { color:#ffffff; font-weight:700; }
	.fruity .gi { color:#ffffff; }
	.fruity .go { color:#444444; background-color:#222222; }
	.fruity .gp { color:#ffffff; }
	.fruity .gs { color:#ffffff; }
	.fruity .gu { color:#ffffff; font-weight:700; }
	.fruity .gt { color:#ffffff; }
	.fruity .kc { color:#fb660a; font-weight:700; }
	.fruity .kd { color:#fb660a; font-weight:700; }
	.fruity .kn { color:#fb660a; font-weight:700; }
	.fruity .kp { color:#fb660a; }
	.fruity .kr { color:#fb660a; font-weight:700; }
	.fruity .kt { color:#cdcaa9; font-weight:700; }
	.fruity .ld { color:#ffffff; }
	.fruity .m { color:#0086f7; font-weight:700; }
	.fruity .s { color:#0086d2; }
	.fruity .na { color:#ff0086; font-weight:700; }
	.fruity .nb { color:#ffffff; }
	.fruity .nc { color:#ffffff; }
	.fruity .no { color:#0086d2; }
	.fruity .nd { color:#ffffff; }
	.fruity .ni { color:#ffffff; }
	.fruity .ne { color:#ffffff; }
	.fruity .nf { color:#ff0086; font-weight:700; }
	.fruity .nl { color:#ffffff; }
	.fruity .nn { color:#ffffff; }
	.fruity .nx { color:#ffffff; }
	.fruity .py { color:#ffffff; }
	.fruity .nt { color:#fb660a; font-weight:700; }
	.fruity .nv { color:#fb660a; }
	.fruity .ow { color:#ffffff; }
	.fruity .w { color:#888888; }
	.fruity .mb { color:#0086f7; font-weight:700; }
	.fruity .mf { color:#0086f7; font-weight:700; }
	.fruity .mh { color:#0086f7; font-weight:700; }
	.fruity .mi { color:#0086f7; font-weight:700; }
	.fruity .mo { color:#0086f7; font-weight:700; }
	.fruity .sa { color:#0086d2; }
	.fruity .sb { color:#0086d2; }
	.fruity .sc { color:#0086d2; }
	.fruity .dl { color:#0086d2; }
	.fruity .sd { color:#0086d2; }
	.fruity .s2 { color:#0086d2; }
	.fruity .se { color:#0086d2; }
	.fruity .sh { color:#0086d2; }
	.fruity .si { color:#0086d2; }
	.fruity .sx { color:#0086d2; }
	.fruity .sr { color:#0086d2; }
	.fruity .s1 { color:#0086d2; }
	.fruity .ss { color:#0086d2; }
	.fruity .bp { color:#ffffff; }
	.fruity .fm { color:#ff0086; font-weight:700; }
	.fruity .vc { color:#fb660a; }
	.fruity .vg { color:#fb660a; }
	.fruity .vi { color:#fb660a; }
	.fruity .vm { color:#fb660a; }
	.fruity .il { color:#0086f7; font-weight:700; }

	.manni { background:#f0f3f3; }
	.manni .hll { background-color:#ffffcc; }
	.manni .c { color:#0099FF; font-style:italic; }
	.manni .err { color:#AA0000; background-color:#FFAAAA; }
	.manni .k { color:#006699; font-weight:700; }
	.manni .o { color:#555555; }
	.manni .ch { color:#0099FF; font-style:italic; }
	.manni .cm { color:#0099FF; font-style:italic; }
	.manni .cp { color:#009999; }
	.manni .cpf { color:#0099FF; font-style:italic; }
	.manni .c1 { color:#0099FF; font-style:italic; }
	.manni .cs { color:#0099FF; font-weight:700; font-style:italic; }
	.manni .gd { background-color:#FFCCCC; border:1px solid #CC0000; }
	.manni .ge { font-style:italic; }
	.manni .gr { color:#FF0000; }
	.manni .gh { color:#003300; font-weight:700; }
	.manni .gi { background-color:#CCFFCC; border:1px solid #00CC00; }
	.manni .go { color:#AAAAAA; }
	.manni .gp { color:#000099; font-weight:700; }
	.manni .gs { font-weight:700; }
	.manni .gu { color:#003300; font-weight:700; }
	.manni .gt { color:#99CC66; }
	.manni .kc { color:#006699; font-weight:700; }
	.manni .kd { color:#006699; font-weight:700; }
	.manni .kn { color:#006699; font-weight:700; }
	.manni .kp { color:#006699; }
	.manni .kr { color:#006699; font-weight:700; }
	.manni .kt { color:#007788; font-weight:700; }
	.manni .m { color:#FF6600; }
	.manni .s { color:#CC3300; }
	.manni .na { color:#330099; }
	.manni .nb { color:#336666; }
	.manni .nc { color:#00AA88; font-weight:700; }
	.manni .no { color:#336600; }
	.manni .nd { color:#9999FF; }
	.manni .ni { color:#999999; font-weight:700; }
	.manni .ne { color:#CC0000; font-weight:700; }
	.manni .nf { color:#CC00FF; }
	.manni .nl { color:#9999FF; }
	.manni .nn { color:#00CCFF; font-weight:700; }
	.manni .nt { color:#330099; font-weight:700; }
	.manni .nv { color:#003333; }
	.manni .ow { color:#000000; font-weight:700; }
	.manni .w { color:#bbbbbb; }
	.manni .mb { color:#FF6600; }
	.manni .mf { color:#FF6600; }
	.manni .mh { color:#FF6600; }
	.manni .mi { color:#FF6600; }
	.manni .mo { color:#FF6600; }
	.manni .sa { color:#CC3300; }
	.manni .sb { color:#CC3300; }
	.manni .sc { color:#CC3300; }
	.manni .dl { color:#CC3300; }
	.manni .sd { color:#CC3300; font-style:italic; }
	.manni .s2 { color:#CC3300; }
	.manni .se { color:#CC3300; font-weight:700; }
	.manni .sh { color:#CC3300; }
	.manni .si { color:#AA0000; }
	.manni .sx { color:#CC3300; }
	.manni .sr { color:#33AAAA; }
	.manni .s1 { color:#CC3300; }
	.manni .ss { color:#FFCC33; }
	.manni .bp { color:#336666; }
	.manni .fm { color:#CC00FF; }
	.manni .vc { color:#003333; }
	.manni .vg { color:#003333; }
	.manni .vi { color:#003333; }
	.manni .vm { color:#003333; }
	.manni .il { color:#FF6600; }

	.monokai { background:#272822; color:#f8f8f2; }
	.monokai .hll { background-color:#49483e; }
	.monokai .c { color:#75715e; }
	.monokai .err { color:#960050; background-color:#1e0010; }
	.monokai .k { color:#66d9ef; }
	.monokai .l { color:#ae81ff; }
	.monokai .n { color:#f8f8f2; }
	.monokai .o { color:#f92672; }
	.monokai .p { color:#f8f8f2; }
	.monokai .ch { color:#75715e; }
	.monokai .cm { color:#75715e; }
	.monokai .cp { color:#75715e; }
	.monokai .cpf { color:#75715e; }
	.monokai .c1 { color:#75715e; }
	.monokai .cs { color:#75715e; }
	.monokai .gd { color:#f92672; }
	.monokai .ge { font-style:italic; }
	.monokai .gi { color:#a6e22e; }
	.monokai .gs { font-weight:700; }
	.monokai .gu { color:#75715e; }
	.monokai .kc { color:#66d9ef; }
	.monokai .kd { color:#66d9ef; }
	.monokai .kn { color:#f92672; }
	.monokai .kp { color:#66d9ef; }
	.monokai .kr { color:#66d9ef; }
	.monokai .kt { color:#66d9ef; }
	.monokai .ld { color:#e6db74; }
	.monokai .m { color:#ae81ff; }
	.monokai .s { color:#e6db74; }
	.monokai .na { color:#a6e22e; }
	.monokai .nb { color:#f8f8f2; }
	.monokai .nc { color:#a6e22e; }
	.monokai .no { color:#66d9ef; }
	.monokai .nd { color:#a6e22e; }
	.monokai .ni { color:#f8f8f2; }
	.monokai .ne { color:#a6e22e; }
	.monokai .nf { color:#a6e22e; }
	.monokai .nl { color:#f8f8f2; }
	.monokai .nn { color:#f8f8f2; }
	.monokai .nx { color:#a6e22e; }
	.monokai .py { color:#f8f8f2; }
	.monokai .nt { color:#f92672; }
	.monokai .nv { color:#f8f8f2; }
	.monokai .ow { color:#f92672; }
	.monokai .w { color:#f8f8f2; }
	.monokai .mb { color:#ae81ff; }
	.monokai .mf { color:#ae81ff; }
	.monokai .mh { color:#ae81ff; }
	.monokai .mi { color:#ae81ff; }
	.monokai .mo { color:#ae81ff; }
	.monokai .sa { color:#e6db74; }
	.monokai .sb { color:#e6db74; }
	.monokai .sc { color:#e6db74; }
	.monokai .dl { color:#e6db74; }
	.monokai .sd { color:#e6db74; }
	.monokai .s2 { color:#e6db74; }
	.monokai .se { color:#ae81ff; }
	.monokai .sh { color:#e6db74; }
	.monokai .si { color:#e6db74; }
	.monokai .sx { color:#e6db74; }
	.monokai .sr { color:#e6db74; }
	.monokai .s1 { color:#e6db74; }
	.monokai .ss { color:#e6db74; }
	.monokai .bp { color:#f8f8f2; }
	.monokai .fm { color:#a6e22e; }
	.monokai .vc { color:#f8f8f2; }
	.monokai .vg { color:#f8f8f2; }
	.monokai .vi { color:#f8f8f2; }
	.monokai .vm { color:#f8f8f2; }
	.monokai .il { color:#ae81ff; }

	.murphy { background:#ffffff; }
	.murphy .hll { background-color:#ffffcc; }
	.murphy .c { color:#666666; font-style:italic; }
	.murphy .err { color:#FF0000; background-color:#FFAAAA; }
	.murphy .k { color:#228899; font-weight:700; }
	.murphy .o { color:#333333; }
	.murphy .ch { color:#666666; font-style:italic; }
	.murphy .cm { color:#666666; font-style:italic; }
	.murphy .cp { color:#557799; }
	.murphy .cpf { color:#666666; font-style:italic; }
	.murphy .c1 { color:#666666; font-style:italic; }
	.murphy .cs { color:#cc0000; font-weight:700; font-style:italic; }
	.murphy .gd { color:#A00000; }
	.murphy .ge { font-style:italic; }
	.murphy .gr { color:#FF0000; }
	.murphy .gh { color:#000080; font-weight:700; }
	.murphy .gi { color:#00A000; }
	.murphy .go { color:#888888; }
	.murphy .gp { color:#c65d09; font-weight:700; }
	.murphy .gs { font-weight:700; }
	.murphy .gu { color:#800080; font-weight:700; }
	.murphy .gt { color:#0044DD; }
	.murphy .kc { color:#228899; font-weight:700; }
	.murphy .kd { color:#228899; font-weight:700; }
	.murphy .kn { color:#228899; font-weight:700; }
	.murphy .kp { color:#0088ff; font-weight:700; }
	.murphy .kr { color:#228899; font-weight:700; }
	.murphy .kt { color:#6666ff; font-weight:700; }
	.murphy .m { color:#6600EE; font-weight:700; }
	.murphy .s { background-color:#e0e0ff; }
	.murphy .na { color:#000077; }
	.murphy .nb { color:#007722; }
	.murphy .nc { color:#ee99ee; font-weight:700; }
	.murphy .no { color:#55eedd; font-weight:700; }
	.murphy .nd { color:#555555; font-weight:700; }
	.murphy .ni { color:#880000; }
	.murphy .ne { color:#FF0000; font-weight:700; }
	.murphy .nf { color:#55eedd; font-weight:700; }
	.murphy .nl { color:#997700; font-weight:700; }
	.murphy .nn { color:#0e84b5; font-weight:700; }
	.murphy .nt { color:#007700; }
	.murphy .nv { color:#003366; }
	.murphy .ow { color:#000000; font-weight:700; }
	.murphy .w { color:#bbbbbb; }
	.murphy .mb { color:#6600EE; font-weight:700; }
	.murphy .mf { color:#6600EE; font-weight:700; }
	.murphy .mh { color:#005588; font-weight:700; }
	.murphy .mi { color:#6666ff; font-weight:700; }
	.murphy .mo { color:#4400EE; font-weight:700; }
	.murphy .sa { background-color:#e0e0ff; }
	.murphy .sb { background-color:#e0e0ff; }
	.murphy .sc { color:#8888FF; }
	.murphy .dl { background-color:#e0e0ff; }
	.murphy .sd { color:#DD4422; }
	.murphy .s2 { background-color:#e0e0ff; }
	.murphy .se { color:#666666; font-weight:700; background-color:#e0e0ff; }
	.murphy .sh { background-color:#e0e0ff; }
	.murphy .si { background-color:#eeeeee; }
	.murphy .sx { color:#ff8888; background-color:#e0e0ff; }
	.murphy .sr { color:#000000; background-color:#e0e0ff; }
	.murphy .s1 { background-color:#e0e0ff; }
	.murphy .ss { color:#ffcc88; }
	.murphy .bp { color:#007722; }
	.murphy .fm { color:#55eedd; font-weight:700; }
	.murphy .vc { color:#ccccff; }
	.murphy .vg { color:#ff8844; }
	.murphy .vi { color:#aaaaff; }
	.murphy .vm { color:#003366; }
	.murphy .il { color:#6666ff; font-weight:700; }

	.native { background:#202020; color:#d0d0d0; }
	.native .hll { background-color:#404040; }
	.native .c { color:#999999; font-style:italic; }
	.native .err { color:#a61717; background-color:#e3d2d2; }
	.native .esc { color:#d0d0d0; }
	.native .g { color:#d0d0d0; }
	.native .k { color:#6ab825; font-weight:700; }
	.native .l { color:#d0d0d0; }
	.native .n { color:#d0d0d0; }
	.native .o { color:#d0d0d0; }
	.native .x { color:#d0d0d0; }
	.native .p { color:#d0d0d0; }
	.native .ch { color:#999999; font-style:italic; }
	.native .cm { color:#999999; font-style:italic; }
	.native .cp { color:#cd2828; font-weight:700; }
	.native .cpf { color:#999999; font-style:italic; }
	.native .c1 { color:#999999; font-style:italic; }
	.native .cs { color:#e50808; font-weight:700; background-color:#520000; }
	.native .gd { color:#d22323; }
	.native .ge { color:#d0d0d0; font-style:italic; }
	.native .gr { color:#d22323; }
	.native .gh { color:#ffffff; font-weight:700; }
	.native .gi { color:#589819; }
	.native .go { color:#cccccc; }
	.native .gp { color:#aaaaaa; }
	.native .gs { color:#d0d0d0; font-weight:700; }
	.native .gu { color:#ffffff; text-decoration:underline; }
	.native .gt { color:#d22323; }
	.native .kc { color:#6ab825; font-weight:700; }
	.native .kd { color:#6ab825; font-weight:700; }
	.native .kn { color:#6ab825; font-weight:700; }
	.native .kp { color:#6ab825; }
	.native .kr { color:#6ab825; font-weight:700; }
	.native .kt { color:#6ab825; font-weight:700; }
	.native .ld { color:#d0d0d0; }
	.native .m { color:#3677a9; }
	.native .s { color:#ed9d13; }
	.native .na { color:#bbbbbb; }
	.native .nb { color:#24909d; }
	.native .nc { color:#447fcf; text-decoration:underline; }
	.native .no { color:#40ffff; }
	.native .nd { color:#ffa500; }
	.native .ni { color:#d0d0d0; }
	.native .ne { color:#bbbbbb; }
	.native .nf { color:#447fcf; }
	.native .nl { color:#d0d0d0; }
	.native .nn { color:#447fcf; text-decoration:underline; }
	.native .nx { color:#d0d0d0; }
	.native .py { color:#d0d0d0; }
	.native .nt { color:#6ab825; font-weight:700; }
	.native .nv { color:#40ffff; }
	.native .ow { color:#6ab825; font-weight:700; }
	.native .w { color:#666666; }
	.native .mb { color:#3677a9; }
	.native .mf { color:#3677a9; }
	.native .mh { color:#3677a9; }
	.native .mi { color:#3677a9; }
	.native .mo { color:#3677a9; }
	.native .sa { color:#ed9d13; }
	.native .sb { color:#ed9d13; }
	.native .sc { color:#ed9d13; }
	.native .dl { color:#ed9d13; }
	.native .sd { color:#ed9d13; }
	.native .s2 { color:#ed9d13; }
	.native .se { color:#ed9d13; }
	.native .sh { color:#ed9d13; }
	.native .si { color:#ed9d13; }
	.native .sx { color:#ffa500; }
	.native .sr { color:#ed9d13; }
	.native .s1 { color:#ed9d13; }
	.native .ss { color:#ed9d13; }
	.native .bp { color:#24909d; }
	.native .fm { color:#447fcf; }
	.native .vc { color:#40ffff; }
	.native .vg { color:#40ffff; }
	.native .vi { color:#40ffff; }
	.native .vm { color:#40ffff; }
	.native .il { color:#3677a9; }

	.pastie { background:#ffffff; }
	.pastie .hll { background-color:#ffffcc; }
	.pastie .c { color:#888888; }
	.pastie .err { color:#a61717; background-color:#e3d2d2; }
	.pastie .k { color:#008800; font-weight:700; }
	.pastie .ch { color:#888888; }
	.pastie .cm { color:#888888; }
	.pastie .cp { color:#cc0000; font-weight:700; }
	.pastie .cpf { color:#888888; }
	.pastie .c1 { color:#888888; }
	.pastie .cs { color:#cc0000; font-weight:700; background-color:#fff0f0; }
	.pastie .gd { color:#000000; background-color:#ffdddd; }
	.pastie .ge { font-style:italic; }
	.pastie .gr { color:#aa0000; }
	.pastie .gh { color:#333333; }
	.pastie .gi { color:#000000; background-color:#ddffdd; }
	.pastie .go { color:#888888; }
	.pastie .gp { color:#555555; }
	.pastie .gs { font-weight:700; }
	.pastie .gu { color:#666666; }
	.pastie .gt { color:#aa0000; }
	.pastie .kc { color:#008800; font-weight:700; }
	.pastie .kd { color:#008800; font-weight:700; }
	.pastie .kn { color:#008800; font-weight:700; }
	.pastie .kp { color:#008800; }
	.pastie .kr { color:#008800; font-weight:700; }
	.pastie .kt { color:#888888; font-weight:700; }
	.pastie .m { color:#0000DD; font-weight:700; }
	.pastie .s { color:#dd2200; background-color:#fff0f0; }
	.pastie .na { color:#336699; }
	.pastie .nb { color:#003388; }
	.pastie .nc { color:#bb0066; font-weight:700; }
	.pastie .no { color:#003366; font-weight:700; }
	.pastie .nd { color:#555555; }
	.pastie .ne { color:#bb0066; font-weight:700; }
	.pastie .nf { color:#0066bb; font-weight:700; }
	.pastie .nl { color:#336699; font-style:italic; }
	.pastie .nn { color:#bb0066; font-weight:700; }
	.pastie .py { color:#336699; font-weight:700; }
	.pastie .nt { color:#bb0066; font-weight:700; }
	.pastie .nv { color:#336699; }
	.pastie .ow { color:#008800; }
	.pastie .w { color:#bbbbbb; }
	.pastie .mb { color:#0000DD; font-weight:700; }
	.pastie .mf { color:#0000DD; font-weight:700; }
	.pastie .mh { color:#0000DD; font-weight:700; }
	.pastie .mi { color:#0000DD; font-weight:700; }
	.pastie .mo { color:#0000DD; font-weight:700; }
	.pastie .sa { color:#dd2200; background-color:#fff0f0; }
	.pastie .sb { color:#dd2200; background-color:#fff0f0; }
	.pastie .sc { color:#dd2200; background-color:#fff0f0; }
	.pastie .dl { color:#dd2200; background-color:#fff0f0; }
	.pastie .sd { color:#dd2200; background-color:#fff0f0; }
	.pastie .s2 { color:#dd2200; background-color:#fff0f0; }
	.pastie .se { color:#0044dd; background-color:#fff0f0; }
	.pastie .sh { color:#dd2200; background-color:#fff0f0; }
	.pastie .si { color:#3333bb; background-color:#fff0f0; }
	.pastie .sx { color:#22bb22; background-color:#f0fff0; }
	.pastie .sr { color:#008800; background-color:#fff0ff; }
	.pastie .s1 { color:#dd2200; background-color:#fff0f0; }
	.pastie .ss { color:#aa6600; background-color:#fff0f0; }
	.pastie .bp { color:#003388; }
	.pastie .fm { color:#0066bb; font-weight:700; }
	.pastie .vc { color:#336699; }
	.pastie .vg { color:#dd7700; }
	.pastie .vi { color:#3333bb; }
	.pastie .vm { color:#336699; }
	.pastie .il { color:#0000DD; font-weight:700; }

	.perldoc { background:#eeeedd; }
	.perldoc .hll { background-color:#ffffcc; }
	.perldoc .c { color:#228B22; }
	.perldoc .err { color:#a61717; background-color:#e3d2d2; }
	.perldoc .k { color:#8B008B; font-weight:700; }
	.perldoc .ch { color:#228B22; }
	.perldoc .cm { color:#228B22; }
	.perldoc .cp { color:#1e889b; }
	.perldoc .cpf { color:#228B22; }
	.perldoc .c1 { color:#228B22; }
	.perldoc .cs { color:#8B008B; font-weight:700; }
	.perldoc .gd { color:#aa0000; }
	.perldoc .ge { font-style:italic; }
	.perldoc .gr { color:#aa0000; }
	.perldoc .gh { color:#000080; font-weight:700; }
	.perldoc .gi { color:#00aa00; }
	.perldoc .go { color:#888888; }
	.perldoc .gp { color:#555555; }
	.perldoc .gs { font-weight:700; }
	.perldoc .gu { color:#800080; font-weight:700; }
	.perldoc .gt { color:#aa0000; }
	.perldoc .kc { color:#8B008B; font-weight:700; }
	.perldoc .kd { color:#8B008B; font-weight:700; }
	.perldoc .kn { color:#8B008B; font-weight:700; }
	.perldoc .kp { color:#8B008B; font-weight:700; }
	.perldoc .kr { color:#8B008B; font-weight:700; }
	.perldoc .kt { color:#00688B; font-weight:700; }
	.perldoc .m { color:#B452CD; }
	.perldoc .s { color:#CD5555; }
	.perldoc .na { color:#658b00; }
	.perldoc .nb { color:#658b00; }
	.perldoc .nc { color:#008b45; font-weight:700; }
	.perldoc .no { color:#00688B; }
	.perldoc .nd { color:#707a7c; }
	.perldoc .ne { color:#008b45; font-weight:700; }
	.perldoc .nf { color:#008b45; }
	.perldoc .nn { color:#008b45; text-decoration:underline; }
	.perldoc .nt { color:#8B008B; font-weight:700; }
	.perldoc .nv { color:#00688B; }
	.perldoc .ow { color:#8B008B; }
	.perldoc .w { color:#bbbbbb; }
	.perldoc .mb { color:#B452CD; }
	.perldoc .mf { color:#B452CD; }
	.perldoc .mh { color:#B452CD; }
	.perldoc .mi { color:#B452CD; }
	.perldoc .mo { color:#B452CD; }
	.perldoc .sa { color:#CD5555; }
	.perldoc .sb { color:#CD5555; }
	.perldoc .sc { color:#CD5555; }
	.perldoc .dl { color:#CD5555; }
	.perldoc .sd { color:#CD5555; }
	.perldoc .s2 { color:#CD5555; }
	.perldoc .se { color:#CD5555; }
	.perldoc .sh { color:#1c7e71; font-style:italic; }
	.perldoc .si { color:#CD5555; }
	.perldoc .sx { color:#cb6c20; }
	.perldoc .sr { color:#1c7e71; }
	.perldoc .s1 { color:#CD5555; }
	.perldoc .ss { color:#CD5555; }
	.perldoc .bp { color:#658b00; }
	.perldoc .fm { color:#008b45; }
	.perldoc .vc { color:#00688B; }
	.perldoc .vg { color:#00688B; }
	.perldoc .vi { color:#00688B; }
	.perldoc .vm { color:#00688B; }
	.perldoc .il { color:#B452CD; }

	.tango { background:#f8f8f8; }
	.tango .hll { background-color:#ffffcc; }
	.tango .c { color:#8f5902; font-style:italic; }
	.tango .err { color:#a40000; border:1px solid #ef2929; }
	.tango .g { color:#000000; }
	.tango .k { color:#204a87; font-weight:700; }
	.tango .l { color:#000000; }
	.tango .n { color:#000000; }
	.tango .o { color:#ce5c00; font-weight:700; }
	.tango .x { color:#000000; }
	.tango .p { color:#000000; font-weight:700; }
	.tango .ch { color:#8f5902; font-style:italic; }
	.tango .cm { color:#8f5902; font-style:italic; }
	.tango .cp { color:#8f5902; font-style:italic; }
	.tango .cpf { color:#8f5902; font-style:italic; }
	.tango .c1 { color:#8f5902; font-style:italic; }
	.tango .cs { color:#8f5902; font-style:italic; }
	.tango .gd { color:#a40000; }
	.tango .ge { color:#000000; font-style:italic; }
	.tango .gr { color:#ef2929; }
	.tango .gh { color:#000080; font-weight:700; }
	.tango .gi { color:#00A000; }
	.tango .go { color:#000000; font-style:italic; }
	.tango .gp { color:#8f5902; }
	.tango .gs { color:#000000; font-weight:700; }
	.tango .gu { color:#800080; font-weight:700; }
	.tango .gt { color:#a40000; font-weight:700; }
	.tango .kc { color:#204a87; font-weight:700; }
	.tango .kd { color:#204a87; font-weight:700; }
	.tango .kn { color:#204a87; font-weight:700; }
	.tango .kp { color:#204a87; font-weight:700; }
	.tango .kr { color:#204a87; font-weight:700; }
	.tango .kt { color:#204a87; font-weight:700; }
	.tango .ld { color:#000000; }
	.tango .m { color:#0000cf; font-weight:700; }
	.tango .s { color:#4e9a06; }
	.tango .na { color:#c4a000; }
	.tango .nb { color:#204a87; }
	.tango .nc { color:#000000; }
	.tango .no { color:#000000; }
	.tango .nd { color:#5c35cc; font-weight:700; }
	.tango .ni { color:#ce5c00; }
	.tango .ne { color:#cc0000; font-weight:700; }
	.tango .nf { color:#000000; }
	.tango .nl { color:#f57900; }
	.tango .nn { color:#000000; }
	.tango .nx { color:#000000; }
	.tango .py { color:#000000; }
	.tango .nt { color:#204a87; font-weight:700; }
	.tango .nv { color:#000000; }
	.tango .ow { color:#204a87; font-weight:700; }
	.tango .w { color:#f8f8f8; text-decoration:underline; }
	.tango .mb { color:#0000cf; font-weight:700; }
	.tango .mf { color:#0000cf; font-weight:700; }
	.tango .mh { color:#0000cf; font-weight:700; }
	.tango .mi { color:#0000cf; font-weight:700; }
	.tango .mo { color:#0000cf; font-weight:700; }
	.tango .sa { color:#4e9a06; }
	.tango .sb { color:#4e9a06; }
	.tango .sc { color:#4e9a06; }
	.tango .dl { color:#4e9a06; }
	.tango .sd { color:#8f5902; font-style:italic; }
	.tango .s2 { color:#4e9a06; }
	.tango .se { color:#4e9a06; }
	.tango .sh { color:#4e9a06; }
	.tango .si { color:#4e9a06; }
	.tango .sx { color:#4e9a06; }
	.tango .sr { color:#4e9a06; }
	.tango .s1 { color:#4e9a06; }
	.tango .ss { color:#4e9a06; }
	.tango .bp { color:#3465a4; }
	.tango .fm { color:#000000; }
	.tango .vc { color:#000000; }
	.tango .vg { color:#000000; }
	.tango .vi { color:#000000; }
	.tango .vm { color:#000000; }
	.tango .il { color:#0000cf; font-weight:700; }

	.trac { background:#ffffff; }
	.trac .hll { background-color:#ffffcc; }
	.trac .c { color:#999988; font-style:italic; }
	.trac .err { color:#a61717; background-color:#e3d2d2; }
	.trac .k { font-weight:700; }
	.trac .o { font-weight:700; }
	.trac .ch { color:#999988; font-style:italic; }
	.trac .cm { color:#999988; font-style:italic; }
	.trac .cp { color:#999999; font-weight:700; }
	.trac .cpf { color:#999988; font-style:italic; }
	.trac .c1 { color:#999988; font-style:italic; }
	.trac .cs { color:#999999; font-weight:700; font-style:italic; }
	.trac .gd { color:#000000; background-color:#ffdddd; }
	.trac .ge { font-style:italic; }
	.trac .gr { color:#aa0000; }
	.trac .gh { color:#999999; }
	.trac .gi { color:#000000; background-color:#ddffdd; }
	.trac .go { color:#888888; }
	.trac .gp { color:#555555; }
	.trac .gs { font-weight:700; }
	.trac .gu { color:#aaaaaa; }
	.trac .gt { color:#aa0000; }
	.trac .kc { font-weight:700; }
	.trac .kd { font-weight:700; }
	.trac .kn { font-weight:700; }
	.trac .kp { font-weight:700; }
	.trac .kr { font-weight:700; }
	.trac .kt { color:#445588; font-weight:700; }
	.trac .m { color:#009999; }
	.trac .s { color:#bb8844; }
	.trac .na { color:#008080; }
	.trac .nb { color:#999999; }
	.trac .nc { color:#445588; font-weight:700; }
	.trac .no { color:#008080; }
	.trac .ni { color:#800080; }
	.trac .ne { color:#990000; font-weight:700; }
	.trac .nf { color:#990000; font-weight:700; }
	.trac .nn { color:#555555; }
	.trac .nt { color:#000080; }
	.trac .nv { color:#008080; }
	.trac .ow { font-weight:700; }
	.trac .w { color:#bbbbbb; }
	.trac .mb { color:#009999; }
	.trac .mf { color:#009999; }
	.trac .mh { color:#009999; }
	.trac .mi { color:#009999; }
	.trac .mo { color:#009999; }
	.trac .sa { color:#bb8844; }
	.trac .sb { color:#bb8844; }
	.trac .sc { color:#bb8844; }
	.trac .dl { color:#bb8844; }
	.trac .sd { color:#bb8844; }
	.trac .s2 { color:#bb8844; }
	.trac .se { color:#bb8844; }
	.trac .sh { color:#bb8844; }
	.trac .si { color:#bb8844; }
	.trac .sx { color:#bb8844; }
	.trac .sr { color:#808000; }
	.trac .s1 { color:#bb8844; }
	.trac .ss { color:#bb8844; }
	.trac .bp { color:#999999; }
	.trac .fm { color:#990000; font-weight:700; }
	.trac .vc { color:#008080; }
	.trac .vg { color:#008080; }
	.trac .vi { color:#008080; }
	.trac .vm { color:#008080; }
	.trac .il { color:#009999; }

	.vim { background:#000000; color:#cccccc; }
	.vim .hll { background-color:#222222; }
	.vim .c { color:#000080; }
	.vim .err { color:#cccccc; border:1px solid #FF0000; }
	.vim .esc { color:#cccccc; }
	.vim .g { color:#cccccc; }
	.vim .k { color:#cdcd00; }
	.vim .l { color:#cccccc; }
	.vim .n { color:#cccccc; }
	.vim .o { color:#3399cc; }
	.vim .x { color:#cccccc; }
	.vim .p { color:#cccccc; }
	.vim .ch { color:#000080; }
	.vim .cm { color:#000080; }
	.vim .cp { color:#000080; }
	.vim .cpf { color:#000080; }
	.vim .c1 { color:#000080; }
	.vim .cs { color:#cd0000; font-weight:700; }
	.vim .gd { color:#cd0000; }
	.vim .ge { color:#cccccc; font-style:italic; }
	.vim .gr { color:#FF0000; }
	.vim .gh { color:#000080; font-weight:700; }
	.vim .gi { color:#00cd00; }
	.vim .go { color:#888888; }
	.vim .gp { color:#000080; font-weight:700; }
	.vim .gs { color:#cccccc; font-weight:700; }
	.vim .gu { color:#800080; font-weight:700; }
	.vim .gt { color:#0044DD; }
	.vim .kc { color:#cdcd00; }
	.vim .kd { color:#00cd00; }
	.vim .kn { color:#cd00cd; }
	.vim .kp { color:#cdcd00; }
	.vim .kr { color:#cdcd00; }
	.vim .kt { color:#00cd00; }
	.vim .ld { color:#cccccc; }
	.vim .m { color:#cd00cd; }
	.vim .s { color:#cd0000; }
	.vim .na { color:#cccccc; }
	.vim .nb { color:#cd00cd; }
	.vim .nc { color:#00cdcd; }
	.vim .no { color:#cccccc; }
	.vim .nd { color:#cccccc; }
	.vim .ni { color:#cccccc; }
	.vim .ne { color:#666699; font-weight:700; }
	.vim .nf { color:#cccccc; }
	.vim .nl { color:#cccccc; }
	.vim .nn { color:#cccccc; }
	.vim .nx { color:#cccccc; }
	.vim .py { color:#cccccc; }
	.vim .nt { color:#cccccc; }
	.vim .nv { color:#00cdcd; }
	.vim .ow { color:#cdcd00; }
	.vim .w { color:#cccccc; }
	.vim .mb { color:#cd00cd; }
	.vim .mf { color:#cd00cd; }
	.vim .mh { color:#cd00cd; }
	.vim .mi { color:#cd00cd; }
	.vim .mo { color:#cd00cd; }
	.vim .sa { color:#cd0000; }
	.vim .sb { color:#cd0000; }
	.vim .sc { color:#cd0000; }
	.vim .dl { color:#cd0000; }
	.vim .sd { color:#cd0000; }
	.vim .s2 { color:#cd0000; }
	.vim .se { color:#cd0000; }
	.vim .sh { color:#cd0000; }
	.vim .si { color:#cd0000; }
	.vim .sx { color:#cd0000; }
	.vim .sr { color:#cd0000; }
	.vim .s1 { color:#cd0000; }
	.vim .ss { color:#cd0000; }
	.vim .bp { color:#cd00cd; }
	.vim .fm { color:#cccccc; }
	.vim .vc { color:#00cdcd; }
	.vim .vg { color:#00cdcd; }
	.vim .vi { color:#00cdcd; }
	.vim .vm { color:#00cdcd; }
	.vim .il { color:#cd00cd; }

	.vs { background:#ffffff; }
	.vs .hll { background-color:#ffffcc; }
	.vs .c { color:#008000; }
	.vs .err { border:1px solid #FF0000; }
	.vs .k { color:#0000ff; }
	.vs .ch { color:#008000; }
	.vs .cm { color:#008000; }
	.vs .cp { color:#0000ff; }
	.vs .cpf { color:#008000; }
	.vs .c1 { color:#008000; }
	.vs .cs { color:#008000; }
	.vs .ge { font-style:italic; }
	.vs .gh { font-weight:700; }
	.vs .gp { font-weight:700; }
	.vs .gs { font-weight:700; }
	.vs .gu { font-weight:700; }
	.vs .kc { color:#0000ff; }
	.vs .kd { color:#0000ff; }
	.vs .kn { color:#0000ff; }
	.vs .kp { color:#0000ff; }
	.vs .kr { color:#0000ff; }
	.vs .kt { color:#2b91af; }
	.vs .s { color:#a31515; }
	.vs .nc { color:#2b91af; }
	.vs .ow { color:#0000ff; }
	.vs .sa { color:#a31515; }
	.vs .sb { color:#a31515; }
	.vs .sc { color:#a31515; }
	.vs .dl { color:#a31515; }
	.vs .sd { color:#a31515; }
	.vs .s2 { color:#a31515; }
	.vs .se { color:#a31515; }
	.vs .sh { color:#a31515; }
	.vs .si { color:#a31515; }
	.vs .sx { color:#a31515; }
	.vs .sr { color:#a31515; }
	.vs .s1 { color:#a31515; }
	.vs .ss { color:#a31515; }
}